import { Link } from "react-router-dom";

import images from "../../api/imagesConfig.js";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import FeatureList from "../features/FeatureList.jsx";

const HomePage = () => {
	let treeImage = images.icons["treeBig"];
	let leafImage = images.icons["leaf"];

	return (
		<div className="page">
			<Header />

			<div className="home-box">
				<div className="tree">
					<img src={treeImage} alt="namawali tree" />
				</div>

				<div className="moto">
					<p>Explore your family tree, find yourself </p>
				</div>
				<div className="search-btn-wrapper">
					<div className="home-item">
						<Link to="/caste/select">Search</Link>
					</div>
					<div className="home-item donate-link">
						<Link to="/donate">Donate</Link>
					</div>
				</div>
			</div>

			<div className="home-info-block">
				<div className="home-info-block-wrapper">
					<p>
						<span>"</span>Namawali platform helps you explore your
						ancestors history and keep them safe for anyone to view.
						As long as this platform exists, you can explore and
						share your identity with your friends and family.
						<span>"</span>
					</p>
					<p className="home-info-quote-person">- Namawali Team</p>
				</div>
			</div>

			<div className="space-block"></div>

			<FeatureList />

			<Footer />
		</div>
	);
};

export default HomePage;
