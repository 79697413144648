import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SiteLogo from "./SiteLogo";
import Nav from "./Nav";
import NavModal from "./NavModal";

const Header = () => {
	let navigate = useNavigate();

	const [showNavModal, setShowNavModal] = useState(false);

	const handleNavMenuClicked = () => {
		setShowNavModal(true);
	};

	const handleNavMenuHide = () => {
		setShowNavModal(false);
	};

	useEffect(() => {
		//! restricts access to the site for production
		if (process.env.NODE_ENV !== "development") {
			// navigate('/maintenance');
		}
	}, []);

	return (
		<>
			<header className="transparent">
				<div className="header-wrapper">
					<div className="logo-box">
						<SiteLogo />
					</div>

					<Nav onClick={handleNavMenuClicked} />
				</div>
			</header>

			<NavModal show={showNavModal} onHide={handleNavMenuHide} />
		</>
	);
};

export default Header;
