import { useNavigate } from "react-router-dom";

import images from "../../api/imagesConfig.js";

const SiteLogo = () => {
	let treeImageUrl = images.icons["tree"];
	let navigate = useNavigate();

	const gotoHome = () => {
		navigate("/");
	};

	return (
		<div className="site-title" onClick={gotoHome}>
			<div className="icon-tree">
				<img src={treeImageUrl} className="icon-tree" />
			</div>

			<div className="icon-text">
				<h2>Namawali</h2>
			</div>

			<div>
				<h1 className="title">Namawali</h1>
			</div>
		</div>
	);
};

export default SiteLogo;
