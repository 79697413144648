import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Cookies from "js-cookie";

import {
	auth,
	logInWithEmailAndPassword,
	signInWithGoogle,
} from "../../api/firebase";
import images from "../../api/imagesConfig.js";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import LoginTreeLink from "../login/LoginTreeLink";
import { resetUserData } from "../utilities/Reset";
import { useUser } from "../utilities/UserContext";

const LoginPage = () => {
	let navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const redirectPath = searchParams.get("redirect"); // null or string
	let googleImage = images.icons["google"];

	const { currentUser, logout, loading } = useUser();
	const [infoMessage, setInfoMessage] = useState(
		"You must be signed in to view this page"
	);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [user] = useAuthState(auth);
	const [welcomeMessage, setWelcomeMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const [query, setQuery] = useState("");
	const [searchOptions, setSearchOptions] = useState([]);
	const [searchResult, setSearchResult] = useState([]);

	const cookiesAccepted = Cookies.get("nama-cookie-accepted");
	const [rememberMeIsChecked, setRememberMeIsChecked] = useState(false);

	const [myPhoto, setMyPhoto] = useState(
		localStorage.getItem("photoUrl")
			? localStorage.getItem("photoUrl")
			: null
	);

	const [userDisplayName, setUserDisplayName] = useState(
		localStorage.getItem("userDisplayName")
			? localStorage.getItem("userDisplayName")
			: ""
	);
	const [userEmail, setUserEmail] = useState(
		localStorage.getItem("userEmail")
			? localStorage.getItem("userEmail")
			: ""
	);

	const [userId, setUserId] = useState(
		localStorage.getItem("userId") ? localStorage.getItem("userId") : ""
	);

	const handleEmailSignIn = async () => {
		if (email.length === 0) {
			setErrorMessage("Email is required.");
			return;
		}
		if (password.length === 0) {
			setErrorMessage("Password is required.");
			return;
		}

		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		if (!emailPattern.test(email)) {
			setErrorMessage("Invalid email address.");
			return;
		}

		const result = await logInWithEmailAndPassword(email, password);
		if (result.length) {
			setErrorMessage("Invalid email or password.");
		}

		const tempAccessToken = localStorage.getItem("accessToken");
		const tempUserDisplayName = localStorage.getItem("userDisplayName");
		const tempUserEmail = localStorage.getItem("userEmail");
		const tempPhotoUrl = localStorage.getItem("photoUrl");
		const tempUserId = localStorage.getItem("userId");
		if (tempAccessToken) {
			setUserDisplayName(tempUserDisplayName);
			setUserEmail(tempUserEmail);
			setMyPhoto(tempPhotoUrl);
			setUserId(tempUserId);

			if (redirectPath) {
				navigate(`/${redirectPath}`);
			}
		}
	};

	const handleSignInWithGoogle = async () => {
		const result = await signInWithGoogle();
		if (result.length) {
			setErrorMessage(result);
		}

		const tempAccessToken = localStorage.getItem("accessToken");
		const tempUserDisplayName = localStorage.getItem("userDisplayName");
		const tempUserEmail = localStorage.getItem("userEmail");
		const tempPhotoUrl = localStorage.getItem("photoUrl");
		const tempUserId = localStorage.getItem("userId");
		if (tempAccessToken) {
			setUserDisplayName(tempUserDisplayName);
			setUserEmail(tempUserEmail);
			setMyPhoto(tempPhotoUrl);
			setUserId(tempUserId);

			if (redirectPath) {
				navigate(`/${redirectPath}`);
			}
		}
	};

	const handleLogout = () => {
		logout();
		resetUserData(navigate);
	};

	const handleAccountSave = () => {
		// save user info
	};

	const handleUserEmailChange = (e) => {
		setErrorMessage("");
		setEmail(e.target.value);
	};

	const handleUserPassChange = (e) => {
		setErrorMessage("");
		setPassword(e.target.value);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleEmailSignIn();
		}
	};

	useEffect(() => {
		if (loading === false) {
			if (currentUser !== null && redirectPath) {
				if (redirectPath === "access") {
					setInfoMessage("Your session has expired. Please sing in.");
					console.log("access token expired from server");
					handleLogout();
				} else {
					navigate(`/${redirectPath}`);
				}
			}
		}
	}, [loading]);

	useEffect(() => {
		if (cookiesAccepted) {
			const cookieRememberMe = Cookies.get("nama-remember-me");
			setRememberMeIsChecked(cookieRememberMe === "true" ? true : false);
			const cookieEmail = Cookies.get("nama-remember-email");
			if (cookieEmail !== undefined) setEmail(cookieEmail);
		}
	}, []);

	useEffect(() => {
		// get user info from server
		if (user !== null) {
			//
		}
	}, [user]);

	useEffect(() => {
		let result = searchOptions.filter((caste) => {
			if (query === "") {
				return "";
			} else if (caste.name.toLowerCase().includes(query.toLowerCase())) {
				return caste;
			}
		});
		setSearchResult(result);
	}, [query]);

	useEffect(() => {
		let message = "Welcome";
		if (userDisplayName !== null && userDisplayName !== "null")
			message += `, ${userDisplayName}`;
		setWelcomeMessage(message);
	}, [user, userDisplayName]);

	return (
		<div className="page login-page">
			<Header />

			{currentUser ? (
				<>
					<h1>{welcomeMessage}</h1>
					<div className="block-wrapper">
						<div className="block">
							{myPhoto ? (
								<img src={myPhoto} alt="my-profile-picture" />
							) : (
								<></>
							)}

							<p>
								<span>Email:</span> {currentUser?.email}
							</p>

							<LoginTreeLink
								user={currentUser}
								onSessionExpired={handleLogout}
							/>

							<div className="account-actions">
								{/* <button onClick={handleAccountSave}>Update</button> */}
								<button onClick={handleLogout}>Logout</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="block-wrapper">
						<div className="login-container block">
							<div className="login-content">
								{redirectPath && currentUser === null && (
									<div className="info-box">
										{infoMessage}
									</div>
								)}

								<h2>Sign In</h2>
								<input
									type="text"
									className="login__textBox"
									value={email}
									onChange={handleUserEmailChange}
									placeholder="E-mail"
								/>
								<input
									type="password"
									className="login__textBox"
									value={password}
									onChange={handleUserPassChange}
									onKeyPress={handleKeyPress}
									placeholder="Password"
								/>

								{errorMessage.length ? (
									<div className="error-message">
										{errorMessage}
									</div>
								) : (
									<></>
								)}

								<button
									className="login__btn"
									onClick={handleEmailSignIn}
								>
									Login
								</button>

								<div className="remember-me">
									<input
										type="checkbox"
										id="remember-me"
										checked={rememberMeIsChecked}
										onChange={() => {
											if (cookiesAccepted) {
												const newValue =
													!rememberMeIsChecked;
												setRememberMeIsChecked(
													newValue
												);
												Cookies.set(
													"nama-remember-me",
													newValue,
													{ expires: 7 }
												);
												if (newValue) {
													Cookies.set(
														"nama-remember-email",
														email,
														{ expires: 7 }
													);
												} else {
													Cookies.remove(
														"nama-remember-email"
													);
													setEmail("");
												}
											}
										}}
									/>
									<label htmlFor="remember-me">
										Remember me
									</label>
								</div>

								<div className="login-extra">
									<Link to="/register">Create account</Link>
									<Link to="/reset">Forgot Password</Link>
								</div>

								<div className="or-divider"></div>

								<button
									className="login__btn login__google"
									onClick={handleSignInWithGoogle}
								>
									<img
										src={googleImage}
										alt="google sign in"
									/>
									<p>Sign in with Google</p>
								</button>
							</div>
						</div>
					</div>
				</>
			)}

			<Footer />
		</div>
	);
};

export default LoginPage;
