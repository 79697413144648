import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import images from "../../api/imagesConfig.js";
import URLS from "../../api/url";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

const DonatePage = () => {
	let navigate = useNavigate();
	const minDonationAmount = 5;
	let donateIcon = images.icons["donate"];

	const [amount, setAmount] = useState(minDonationAmount);
	const [donationId, setDonationId] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [classA, setClassA] = useState("active");
	const [classB, setClassB] = useState("");
	const [classC, setClassC] = useState("");

	const donate = () => {
		const url = URLS.base_url + URLS.donate.base;

		axios
			.post(url, { donationId: donationId })
			.then((res) => {
				const result = res.data;
				if (result.success) {
					const stripeUrl = result.url;
					if (stripeUrl.length) window.location = stripeUrl;
				} else {
					alert("donation failed");
				}

				setIsLoading(true);
			})
			.catch((error) => {
				if (error.code == "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					// resetUserData();
				}
			});
	};

	const handleDonationSelect = (val) => {
		switch (val) {
			case 1:
				setClassA("active");
				setClassB("");
				setClassC("");
				setDonationId(1);
				break;
			case 2:
				setClassA("");
				setClassB("active");
				setClassC("");
				setDonationId(2);
				break;
			case 3:
				setClassA("");
				setClassB("");
				setClassC("active");
				setDonationId(3);
				break;
		}
	};

	const handleAmountChange = (e) => {
		const amt = e.target.value;
		setAmount(amt);
	};

	const handleDonate = () => {
		//
		if (amount >= minDonationAmount) {
			donate();
			// alert('donate ' + donationId);
		} else {
			alert("Donation amount is too low");
		}
	};

	return (
		<div className="page page-donate">
			<Header />

			<h1>Donate Page</h1>

			<div className="block-wrapper">
				<div className="donate-container block">
					<p>
						Help us keep this platform live by donating any amount
						you choose.
					</p>

					<div id="donate-icon">
						<img src={donateIcon} alt="donate-help" />
					</div>

					<div className="donation-wrapper">
						<div
							className={"donation-item donation-5 " + classA}
							onClick={() => handleDonationSelect(1)}
						>
							£ 5
						</div>
						<div
							className={"donation-item donation-10 " + classB}
							onClick={() => handleDonationSelect(2)}
						>
							£ 10
						</div>
						<div
							className={"donation-item donation-20 " + classC}
							onClick={() => handleDonationSelect(3)}
						>
							£ 20
						</div>
					</div>

					<div className="donation-button-wrapper">
						<button onClick={handleDonate}>Donate</button>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default DonatePage;
