import { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../utilities/UserContext";

const NavModal = ({ show, onHide }) => {
	const { currentUser } = useUser();

	const showNavMenu = show ? "show" : "hide";

	const handleHideClick = () => {
		onHide();
	};

	return (
		<div className={"modal-box-container " + showNavMenu}>
			<div className="menu-mobile modal-box-wrapper">
				<button className="close" onClick={handleHideClick}>
					close
				</button>

				<div className="modal-box-wrapper">
					<div className="menu-mobile-container">
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/caste/select">Search</Link>
						</li>
						<li>
							<Link to="/features">Features</Link>
						</li>
						<li>
							<Link to="/faq">FAQ</Link>
						</li>
						<li>
							{currentUser ? (
								<Link to="/login">Account</Link>
							) : (
								<Link to="/login">Sign In</Link>
							)}
						</li>
						<li className="donate-button">
							<Link to="/donate">Donate</Link>
						</li>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavModal;
